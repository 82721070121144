import React from 'react';
import { Link, graphql, PageRendererProps } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImage } from '@fortawesome/free-solid-svg-icons';

import Layout from '../../components/Layout';
import EventDate from '../../components/EventDate';
import img404 from '../../img/img404.svg';

interface Props extends PageRendererProps {
  data: {
    allMarkdownRemark: {
      edges: {
        node: {
          excerpt: string;
          id: string;
          fields: {
            slug: string;
          };
          frontmatter: {
            title: string;
            startDate: string;
            endDate?: string;
            description: string;
            indexImage: {
              image: null | {
                childImageSharp: {
                  thumbnail: {
                    src: string;
                    width: number;
                    height: number;
                  };
                };
              };
              alt?: string;
            };
            slides: {
              image: {
                publicURL: string;
              };
            }[];
          };
        };
      }[];
    };
  };
}

export const galleriesIndexPageQuery = graphql`
  query GalleriesIndexPageQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [fields___slug] }
      filter: { frontmatter: { templateKey: { eq: "gallery-page" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            startDate
            endDate
            description
            indexImage {
              image {
                childImageSharp {
                  thumbnail: resize(
                    width: 512
                    height: 384
                    cropFocus: ENTROPY
                  ) {
                    src
                    width
                    height
                  }
                }
              }
              alt
            }
            slides {
              image {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`;

export default function GalleriesIndexPage({ data, location }: Props) {
  return (
    <Layout
      title="Galerien"
      location={location.href}
      schema={{
        '@type': 'CollectionPage',
        headline: 'Galerien',
        description:
          'Hier sehen Sie eine Übersicht aller Bildgalerien. Wählen Sie die gewünschte aus, um sich alle Bilder anzeigen zu lassen.',
      }}
    >
      <section className="section">
        <div className="container">
          <h1 className="title">Galerien</h1>
          <p>
            Hier sehen Sie eine Übersicht aller Bildgalerien. Wählen Sie die
            gewünschte aus, um sich alle Bilder der Galerie anzeigen zu lassen.
          </p>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="columns is-multiline">
            {data.allMarkdownRemark.edges.map(
              ({ node: { fields, frontmatter, id } }) => (
                <div
                  key={id}
                  className="column is-one-third-tablet is-one-quarter-desktop is-one-fifth-widescreen"
                >
                  <div
                    className="card is-fullheight"
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <div className="card-image">
                      <figure className="image is-4by3">
                        {frontmatter.indexImage.image === null ? (
                          <img src={img404} alt="Nicht gefunden" />
                        ) : (
                          <img
                            src={
                              frontmatter.indexImage.image.childImageSharp
                                .thumbnail.src
                            }
                            alt={frontmatter.indexImage.alt}
                          />
                        )}
                      </figure>
                    </div>
                    <div className="card-content">
                      <p className="has-text-weight-bold">
                        <EventDate
                          startDate={frontmatter.startDate}
                          endDate={frontmatter.endDate}
                        />
                      </p>
                      <p>{frontmatter.title}</p>
                    </div>
                    <footer className="card-footer">
                      <div className="card-footer-item">
                        <span className="icon">
                          <FontAwesomeIcon icon={faFileImage} />
                        </span>
                        {frontmatter.slides ? frontmatter.slides.length : 0}
                      </div>
                      <Link className="card-footer-item" to={fields.slug}>
                        Ansehen...
                      </Link>
                    </footer>
                  </div>
                </div>
              ),
            )}
          </div>
        </div>
      </section>
    </Layout>
  );
}
