import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getDate } from '../utils';

interface Props {
  startDate: string;
  endDate?: string;
}

export default function EventDate({ startDate, endDate }: Props): JSX.Element {
  const {
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          languageCode
          countryCode
        }
      }
    }
  `);
  const locale = `${siteMetadata.languageCode}-${siteMetadata.countryCode}`;
  const start = new Date(startDate);
  const end = endDate ? new Date(endDate) : start;
  const isOneDay = start.toDateString() === end.toDateString();

  return (
    <>
      <time dateTime={start.toISOString()}>
        {getDate(start, {
          locales: locale,
          year: isOneDay || start.getFullYear() !== end.getFullYear(),
          month: isOneDay || start.getMonth() !== end.getMonth(),
        })}
      </time>
      {!isOneDay && (
        <>
          {' - '}
          <time dateTime={end.toISOString()}>
            {getDate(end, {
              locales: locale,
            })}
          </time>
        </>
      )}
    </>
  );
}
